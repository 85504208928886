// import { AiFillHeart } from "react-icons/ai";
// styles
import styles from "./Footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className="container">
        <p className="text-muted">
          © {currentYear} AVFREEX24 Review all rights reserved.
        </p>
        <p className={styles.created}>
          {" "}
          <span>
            <img className={styles.heart} src={"https://firebasestorage.googleapis.com/v0/b/ufaspin.appspot.com/o/w-pr-logo.svg?alt=media&token=514387ce-5851-4c37-a649-9db0f9398418"} alt={"SEO-SITE"} />
          </span>
          {" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
