import { NavLink } from "react-router-dom";
// import { IoGameController } from "react-icons/io5";
// import { SiteLogo } from "./w-pr-logo.svg";
// styles
import styles from "./Header.module.css";

const Header = () => {
  const activeStyle = {
    color: "#fff",
  };

  return (
    <header className={styles.header}>
      <div className={`${styles.container} container`}>
        <NavLink to="/">
          <img className={styles.logo} src={"https://firebasestorage.googleapis.com/v0/b/ufaspin.appspot.com/o/w-pr-logo.svg?alt=media&token=514387ce-5851-4c37-a649-9db0f9398418"} alt={"SEO-SITE"} />
        </NavLink>
        <nav className={styles.navigation}>
          <ul>
            <li>
              <NavLink
                to="/"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <h1>AVFREE<span style={{color: "orange"}}>X</span>24<span style={{color: "#88FF00"}}>GuideYED</span></h1>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="popular"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
               <b><span style={{color: "#FFAF26"}}>น้องๆไซด์ไลน์ ยอดYEDนิยม</span></b>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="favorites"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <img style={{height: "25px"}} src={"https://firebasestorage.googleapis.com/v0/b/ufaspin.appspot.com/o/favorites-icon-png-16.png?alt=media&token=311a08a4-77ec-4c4c-a153-3ea7f2c47922"} alt={"favorites"} />
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
