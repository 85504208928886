import { useContext } from "react";
import { Link } from "react-router-dom";
import { AiFillWindows, AiFillHeart } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
// styles
import styles from "./GameItem.module.css";

import { FavoritesContext } from "../../context/FavoritesContext";

const GameItem = ({ item: game }) => {
  const { addToFavorite, gameIsFavorite } = useContext(FavoritesContext);

  return (
    <div className={styles.card}>
      <Link to={`/${game.id}`} className={styles.card_header}>
        <img className={styles.thumbnail} src={game.pic} alt="test" />
      </Link>
      <div className={styles.card_body}>
        <Link to={`/${game.id}`} className={styles.title}>
          {game.price}
        </Link>
        <p className={`${styles.description} text-muted`}>
          {game.price.substr(0, 70)}...
        </p>
        <div className={styles.card_footer}>
          <div>
            <span className={styles.badge}>{game.price}</span>
            {game.price.includes("PC (หญิงแท้)") ? (
              <AiFillWindows
                className={styles.platform_icon}
                title="พร้อม YEDในที่จัดหาเอง"
              />
            ) : (
              <GoBrowser
                className={styles.platform_icon}
                title="พร้อม YEDที่ไหนก็ได้"
              />
            )}
          </div>
          <button
            onClick={() => addToFavorite(game)}
            className={styles.btn}
            title={
              gameIsFavorite(game.id)
                ? "ลบออกจากรายการโปรด"
                : "เพิ่มในรายการโปรด"
            }
          >
            <AiFillHeart
              className={styles.heart_icon}
              style={{ color: gameIsFavorite(game.id) ? "red" : "#aaaaaa" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameItem;
