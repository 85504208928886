import { useContext } from "react";
import { useParams } from "react-router";
import { AiFillHeart, AiFillWindows } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
import { useFetch } from "../hooks/useFetch";
import { FavoritesContext } from "../context/FavoritesContext";



// styles
import styles from "./Details.module.css";

import Spinner from "../components/ui/Spinner";

const Details = () => {
  const { id } = useParams();
  const {
    data: game,
    isPending,
    error,
  } = useFetch(`https://jav-free-default-rtdb.firebaseio.com/sidelinethailand/${id}.json`, {
    id,
  });

  const { gameIsFavorite, addToFavorite } = useContext(FavoritesContext);
  console.log(game)
  return (
    
    <section className={styles.game_details}>
      {isPending && <Spinner />}
      {error && <p>{error}</p>}
      {game && (
        <article className={styles.article}>
          <img
            className={styles.pic}
            src={game.pic}
            alt={game.id}
          />
          <h1 className={styles.title}><h1>AVFREE<span style={{color: "orange"}}>X</span>24<span style={{color: "#88FF00"}}>GuideYED</span><span style={{color: "#88FF00"}}>Review</span></h1><span style={{color: "#FFF",fontSize: "5rem"}}>{game.title}</span></h1>
          {game.price.split(/(\r\n|\r|\n)/gi).map((paragraph, idx) => (
            <p key={idx} style={{ margin: "20px 0", fontSize: "15px" }}>
              {paragraph}
            </p>
          ))}

          <h3>ข้อมูลเพิ่มเติม</h3>
          <ul className={styles.info_list}>
            <li>
              <span className="text-muted">ชื่อน้อง</span>
              <p>{game.id}</p>
            </li>
            <li>
              <span className="text-muted">สังกัด</span>
              <p>{game.id}</p>
            </li>
            <li>
              <span className="text-muted">ประเภท: ใน/นอก/สถานที่</span>
              <p>{game.price}</p>
            </li>
            <li>
              <span className="text-muted">วันที่เปิดตัว</span>
              <p>{game.id}</p>
            </li>
            <li>
              <span className="text-muted">สเปค</span>
              <p>{game.id}</p>
            </li>
            <li>
              <span className="text-muted">ฟังก์ชั่นที่รองรับ</span>
              <div className={styles.platform}>
                {game.price.split('฿')[1].split(' ')[1] === "หญิงแท้" ? (
                  <AiFillWindows className={styles.platform_icon} />
                ) : (
                  <GoBrowser className={styles.platform_icon} />
                )}
                <p>{game.price}</p>
              </div>
            </li>
          </ul>

          {game?.screenshots && <h3>ตัวอย่าง ไซด์ไลน์ {game.id} </h3>}
          {game?.screenshots && (
            <div className={styles.screenshot_grid}>
              {game?.screenshots?.map(({ image }) => (
                <div key={image}>
                  <img src={image} alt={game.id} />
                </div>
              ))}
            </div>
          )}
          <br />
          {game?.minimum_system_requirements && (
            <h2>สเปคคอมที่เกมส์ {game.id} ต้องการ (Windows)</h2>
          )}
          {game?.minimum_system_requirements && (
            <ul className={styles.info_list}>
              <li>
                <span className="text-muted">OS</span>
                <p>{game.price}</p>
              </li>
              <li>
                <span className="text-muted">Processor ซีพียู</span>
                <p>{game.id}</p>
              </li>
              <li>
                <span className="text-muted">Memory แรม</span>
                <p>{game.price}</p>
              </li>
              <li>
                <span className="text-muted">Graphics การ์ดจอ</span>
                <p>{game.price}</p>
              </li>
              <li>
                <span className="text-muted">Storage พื้นที่จัดเก็บ</span>
                <p>{game.price}</p>
              </li>
            </ul>
          )}

          <button
            onClick={() => addToFavorite(game)}
            className={`btn ${styles.btn_favorite}`}
            title={
              gameIsFavorite(game.id)
                ? "Remove from favorites"
                : "Add to favorites"
            }
          >
            <AiFillHeart
              className={styles.heart_icon}
              color={gameIsFavorite(game.id) ? "#ff0000" : "#fff"}
            />
          </button>
        </article>
      )}
    </section>
  );
};

export default Details;
