import { Link } from "react-router-dom";

import styles from "./NotFound.module.css";

const NotFound = () => {
  return (
    <section className={styles.not_found}>
      <h1 className="title">404 - ยังไม่มีอะไร</h1>
      <Link to="/" className={styles.link}>
        กลับไปก่อน
      </Link>
    </section>
  );
};

export default NotFound;
