import { useState } from "react";
import { useFetch } from "../hooks/useFetch";

// styles
import styles from "./Popular.module.css";

import GameList from "../components/games/GameList";
import Spinner from "../components/ui/Spinner";

const Popular = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  let url = `https://jav-free-default-rtdb.firebaseio.com/sidelinethailand.json`;

  if (selectedCategory) {
    url = `https://jav-free-default-rtdb.firebaseio.com/sidelinethailand.json`;
  }

  const { data, isPending, error } = useFetch(url);
  const currentYear = new Date().getFullYear();
  const currentMonth = Intl.DateTimeFormat("th-TH", { month: "long" }).format(
    new Date()
  );

  const categories = [
    "ฉีดวัคซีนแล้ว",
    "ฟิวแฟน-เมียจ๋า",
    "สายบู๊",
    "แลกลิ้น",
    "ลูกครึ่ง",
    "นมแท้",
    "ไม่มีรอยสัก",
    "จิมิไร้ขน",
    "รับงานคาชุด",
    "B2B",
  ];

  return (
    <section className={styles.popular}>
      <h1 className="title">
        10 ไซด์ไลน์ ในสเปค แนะนำ{" "}
        <span className={styles.lead}>
          {selectedCategory ? selectedCategory : "น่าYED"}
        </span>{" "}
        ไซด์ไลน์ TOPYED ประจำเดือน {currentMonth} ประจำปี {currentYear}
      </h1>

      <div className={styles.filter}>
        <label htmlFor="category">10 ไซด์ไลน์ยอดYED:</label>
        <select
          defaultValue="Select Category"
          id="category"
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option disabled>เลือกสเปค</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              แนว {category}
            </option>
          ))}
        </select>
      </div>

      {isPending && <Spinner />}
      {error && <p>{error}</p>}
      {data && <GameList items={data.slice(0, 10)} />}
    </section>
  );
};

export default Popular;
